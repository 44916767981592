import './App.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function App() {
  return (
    <div className="container">
      <header className='header'>
        <img src="realate-icon.svg " alt="Realate Icon" className='realate-icon' />
      </header>
      <Carousel showThumbs={true} showStatus={false} showArrows={false} swipeable={true} autoPlay={true} infiniteLoop={true}>
        <div className="card-1">
          <div className="background-card">
            <div className='image-div'>
              <img src="women.svg" alt="women-logo" className='background-image-1' />
            </div>
            <div className="textDiv">
              <p className='card-text-1'>Comments</p>
              <p className='card-text-1'>Instead of</p>
              <p className='card-text-1'>Swipes.</p>
              <p className="card-text-2">Cuz awkward small talk is the dream, right?</p>
            </div>
          </div>
        </div>
        <div className="card-1">
          <div className="background-card">
            <div className='image-div-2'>
              <img src="dog.svg" alt="dog-logo" className='background-image-2' />
            </div>
            <div className="textDiv">
              <p className="card-text-3">Forget the creeps.</p>
              <p className='card-text-1'>Best 25 likes</p>
              <p className='card-text-1'>at a time.</p>
            </div>
          </div>
        </div>
        <div className="card-1">
          <div className="background-card">
            <div className='image-div-3'>
              <img src="flower.svg" alt="flower-logo" className='background-image-3' />
            </div>
            <div className="textDiv">
              <p className='card-text-1'>No more</p>
              <p className='card-text-1'>superficial</p>
              <p className='card-text-1'>convos.</p>
              <p className="card-text-2">Get to know people for real.</p>

            </div>
          </div>
        </div>

      </Carousel>
      <div className='shadow-text heading-text'>
        <p className='text-1'>Not <span className='yellow-text'>another</span> dating app </p>
      </div>

      <div className='img'>
        <img src="/person-constellation.svg" alt="Person Constellation" className='persons-icon' />
      </div>

      <div className='heading-text'>
        <p className='text-2'>Who <span className='yellow-text2'>should join?</span> </p>
      </div>


      <Carousel showThumbs={false} showStatus={false} showArrows={false} swipeable={true} autoPlay={true} infiniteLoop={true}>
        <div className="card-1">
          <div className="background-card-1">

            <div className="textDiv">
              <p className="card-text-3">If  you think </p>
              <p className='card-text-1'>Dating apps today</p>
              <p className='card-text-1'>are a waste</p>
              <p className='card-text-1'>of time.</p>

            </div>
          </div>
        </div>
        <div className="card-1">
          <div className="background-card-1">

            <div className="textDiv">
              <p className="card-text-3">If  you think </p>
              <p className='card-text-1'>{`Quality >>`}</p>
              <p className='card-text-1'>Quantity</p>
            </div>
          </div>
        </div>
        <div className="card-1">
          <div className="background-card-1">

            <div className="textDiv">
              <p className="card-text-3">If  you’re tired of</p>
              <p className='card-text-1'>{`“Hi beautiful”,`}</p>
              <p className='card-text-1'>{`“How r u”`}</p>
              <p className='card-text-1'>{`& “ssup”`}</p>

            </div>
          </div>
        </div>

      </Carousel>

      <div className="coming-soon">
        <p className="text-5">
          Change the way you date.
        </p>

        <p className="coming">
          Coming
        </p>
        <p className="soon">
          Soon.
        </p>
      </div>

      <div className="button-div">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScpLzHHsyMMAxgqPzm1mGeOhAaybaxHPdWwptA9ZU1iE1RlZA/viewform">

          <button className="button">
            <p className="button-text">
              Join now
            </p>
            <img src="/arrow.svg" alt="" className='button-img' />

          </button>
        </a>
      </div>




      <footer className='footer'>
        <img src="realate-icon.svg " alt="Realate Icon" className='realate-icon' />

        <div className='footer-1'>
          <a href="https://www.linkedin.com/company/hartindia/">
            <img src="linkedin-logo.svg" alt="" className='logo' />
          </a>
          <a href="https://www.instagram.com/realate.co/">
            <img src="instagram-logo.svg" alt="" className='logo' />
          </a>

        </div>

        <div className='center-heading'>
          <a className="heading-link" href="/policy">
            Privacy Policy
          </a>
        </div>

      </footer>

    </div>
  );
}

export default App;