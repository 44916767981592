import "./App.css";

function Policy() {
  return (
    <div>
      <div className="main-heading">
        <h1>1. OUR COMMITMENT TO YOU</h1>
      </div>
      <div className="content">
        <p>
          At Realate, your privacy is a top priority. Your privacy is at the
          core of the way we design and build the services and products you know
          and love, so that you can fully trust them and focus on building
          meaningful connections. We appreciate that you put your trust in us
          when you provide us with your information, and we do not take this
          lightly.
        </p>
      </div>
      <div className="sub-heading">
        <h1>Our commitment to privacy.</h1>
      </div>
      <div className="content">
        <p>
          At Realate, your privacy is a top priority. Your privacy is at the
          core of the way we design and build the services and products you know
          and love, so that you can fully trust them and focus on building
          meaningful connections. We appreciate that you put your trust in us
          when you provide us with your information, and we do not take this
          lightly.
        </p>
      </div>
      <div className="sub-heading">
        <h1>Our commitment to transparency.</h1>
      </div>
      <div className="content">
        <p>
          Because we use many of the same online services you do, we know that
          insufficient information and overly complicated language are common
          issues in privacy policies. We take the exact opposite approach: we’re
          doing our best to write our Privacy Policy and related documents in
          plain language. We actually want you to read our policies and
          understand our privacy practices!
        </p>
      </div>
      <div className="sub-heading">
        <h1>Our commitment to security.</h1>
      </div>
      <div className="content">
        <p>
          We have teams dedicated to keeping your data safe and secure. We
          constantly update our security practices and invest in our security
          efforts to enhance the safety of your information
        </p>
      </div>

      <div className="main-heading">
        <h1>2. PRIVACY POLICY</h1>
      </div>
      <div className="content">
        <p>
          Welcome to Realate’s Privacy Policy. Thank you for taking the time to
          read it. We appreciate that you trust us with your information and we
          intend to always keep that trust. This starts with making sure you
          understand the information we collect, why we collect it, how it is
          used and your choices regarding your information. This Policy
          describes our privacy practices in plain language, keeping legal and
          technical jargon to a minimum. This Privacy Policy applies from March
          1, 2024.
        </p>
      </div>
      <div className="sub-heading">
        <h1>1. Where This Privacy Policy Applies</h1>
      </div>
      <div className="content">
        <p>
          This Privacy Policy applies to websites, apps, events and other
          services we operate under the brand Realate. For simplicity, we refer
          to all of these as our “services” in this Privacy Policy. To make it
          extra clear, we’ve added links to this Privacy Policy on all
          applicable services. Some services may require their own unique
          privacy policy. If a service has its own privacy policy, then that
          policy -- not this Privacy Policy -- applies.
        </p>
      </div>

      <div className="sub-heading">
        <h1>2. Information We Collect</h1>
      </div>
      <div className="content">
        <p>
          It goes without saying, we can’t help you develop meaningful
          connections without some information about you, such as basic profile
          details and the types of people you’d like to meet. We also collect
          information about your use of our services such as access logs, as
          well as information from third parties, like when you access our
          services through your social media account or when you upload
          information from your social media account to complete your profile.
          If you want additional info, we go into more detail below.
        </p>
      </div>
      <div className="sub-heading1">
        <h1>Information you give us</h1>
      </div>
      <div className="content">
        <p>
          You choose to give us certain information when using our services.
          This includes:
        </p>
      </div>
      <div className="content">
        <p>
          When you create an account, you provide us with at least your phone
          number and email address, as well as some basic details necessary for
          the service to work, such as your gender, date of birth, and who you’d
          like to connect with.
        </p>
      </div>
      <div className="content">
        <p>
          When you complete your profile, you have the option to share your
          sexual orientation, ethnicity, religious beliefs, and political
          opinions with us. Where you provide such elements to us, you consent
          to us using it for the purposes identified and as laid out in this
          Privacy Policy. You can also share additional information with us,
          such as details on your bio and your interests, as well as content
          such as photos, videos, and audio clips. To add certain content, like
          pictures or videos, you may allow us to access your camera, photo
          album, or microphone.
        </p>
      </div>
      <div className="content">
        <p>
          When you subscribe to a paid service or make a purchase directly from
          us (rather than through a platform such as iOS or Android), you
          provide us with information related to the purchases you make and our
          payment processors with information such as your debit or credit card
          number or other financial information.
        </p>
      </div>
      <div className="content">
        <p>
          When you participate in surveys, focus groups or market studies, you
          give us your insights into our products and services, responses to our
          questions and testimonials. When you choose to participate in our
          promotions, events or contests, we collect the information that you
          use to register or enter.
        </p>
      </div>
      <div className="content">
        <p>
          Of course, we also process your chats with other users as well as the
          content you publish to operate and secure the services, and to keep
          our community safe.
        </p>
      </div>
      <div className="sub-heading1">
        <h1>Information we receive from others</h1>
      </div>
      <div className="content">
        <p>
          In addition to the information you may provide us directly, we receive
          information about you from others, including:
        </p>
      </div>
      <div className="sub-heading">
        <h1>Users</h1>
      </div>
      <div className="content">
        <p>
          Users may provide information about you as they use our services, for
          instance as they interact with you or if they submit a report
          involving you.
        </p>
      </div>

      <div className="sub-heading">
        <h1>Social Media</h1>
      </div>
      <div className="content">
        <p>
          You may decide to share information with us through your social media
          account, for instance if you decide to create and log into your
          Realate account via your social media or other account (e.g.,
          Facebook, Google or Apple) or to upload onto our services information
          such as photos from one of your social media accounts (e.g., Facebook
          or Instagram).
        </p>
      </div>

      <div className="sub-heading">
        <h1>Other Partners</h1>
      </div>
      <div className="content">
        <p>
          We may receive information about you from our partners where our ads
          are published on a partner’s service (in which case they may pass
          along details on a campaign’s success). Where legally allowed, we can
          also receive information about suspected or convicted bad actors from
          third parties as part of our efforts to ensure our users’ safety and
          security.
        </p>
      </div>

      <div className="sub-heading">
        <h1>
          Information Generated or Automatically Collected When You Use Our
          Services
        </h1>
      </div>
      <div className="content">
        <p>
          When you use our services, this generates technical data about which
          features you’ve used, how you’ve used them and the devices you use to
          access our services. See below for more details.
        </p>
      </div>

      <div className="sub-heading">
        <h1>Usage Information</h1>
      </div>
      <div className="content">
        <p>
          Using the services generates data about your activity on our services,
          for instance how you use them (e.g., when you logged in, features
          you’ve been using, actions taken, information shown to you, referring
          webpages address and ads that you interacted with) and your
          interactions with other users (e.g., users you connect and interact
          with, and when you matched and exchanged messages with them).
        </p>
      </div>

      <div className="sub-heading">
        <h1>Device Information</h1>
      </div>
      <div className="content">
        <p>
          We collect information from and about the device(s) you use to access
          our services, including hardware and software information such as IP
          address, device ID and type, apps settings and characteristics, app
          crashes, advertising IDs (which are randomly generated numbers that
          you can reset by going into your device settings and, in some cases,
          disable entirely), and identifiers associated with cookies or other
          technologies that may uniquely identify a device or browser.
        </p>
      </div>
      <div className="sub-heading">
        <h1>Information Collected by Cookies and Similar Technologies</h1>
      </div>
      <div className="content">
        <p>
          We use and may allow others to use cookies and similar technologies
          (e.g., web beacons, pixels and SDKs) to recognize you and/or your
          device(s). You may read our Cookie Policy for more information on why
          we use them and how you can better control their use.
        </p>
      </div>
      <div className="content">
        <p>
          Some web browsers (including Safari, Internet Explorer, Firefox and
          Chrome) have a “Do Not Track” (“DNT”) feature that tells a website
          that a user does not want to have his or her online activity tracked.
          If a website that responds to a DNT signal receives a DNT signal, the
          browser can block that website from collecting certain information
          about the browser’s user. Not all browsers offer a DNT option and DNT
          signals are not yet uniform. For this reason, many businesses,
          including Realate, do not currently respond to DNT signals.
        </p>
      </div>

      <div className="sub-heading">
        <h1>Other Information with Your Consent</h1>
      </div>
      <div className="content">
        <p>
          <strong>Precise Geolocation Data:</strong> If you give us your
          consent, we can collect your precise geolocation (latitude and
          longitude) from your device. The collection of your geolocation may
          occur in the background even when you aren’t using the services if the
          permission you gave us expressly permits such collection. If you
          decline permission for us to collect your precise geolocation, we will
          not collect it, and our services that rely on precise geolocation may
          not be available to you.
        </p>
      </div>
      <div className="content">
        <p>
          <strong>Selfie Verification Data:</strong> If you choose to
          participate in our Selfie Verification feature, we collect your face
          geometry data, which may be considered biometric data in some
          jurisdictions, to verify that you’re the real you. You can learn more
          about Selfie Verification and how we process your face geometry data
          here.
        </p>
      </div>
      <div className="content">
        <p>
          <strong>Other Information:</strong> We may collect other information
          with your permission, such as photos and videos (for instance, if you
          want to publish a photo or video or participate in streaming features
          on our services).
        </p>
      </div>

      <div className="sub-heading">
        <h1>3. How We Use Information</h1>
      </div>
      <div className="content">
        <p>
          The main reason we use your information is to deliver and improve our
          services. Additionally, we use your info to help keep you and our
          community safe, and to provide you with advertising that may be of
          interest to you. Read on for a more detailed explanation of the
          various reasons for which we use your information, together with
          practical examples.
        </p>
      </div>
      <div className="sub-heading">
        <h1>A. To Administer Your Account and Provide Our Services to You</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>Create and manage your account</li>
            <li>
              Provide you with customer support and respond to your requests
            </li>
            <li>Communicate with you about our services</li>
            <li>
              Personalize pricing, offer discounts and other promotions, and
              complete your transactions
            </li>
            <li>Administer sweepstakes and contests</li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>B. To Help You Connect with Other Users</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              Recommend you to other users and recommend other users to you
            </li>
            <li>Show users’ profiles to one another</li>
            <li>Enable users to search for and connect with users</li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>
          C. To Provide Offers and Operate Advertising and Marketing Campaigns
        </h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              Perform and measure the effectiveness of advertising campaigns on
              our services and marketing our services off our platform
            </li>
            <li>
              Communicate with you about products or services that we believe
              may interest you
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>D. To Improve Our Services and Develop New Ones</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>Administer focus groups, market studies and surveys</li>
            <li>
              Review interactions with customer care teams to improve our
              quality of service
            </li>
            <li>
              Understand how users typically use the services to improve them
              (for instance, we may decide to change the look and feel or even
              substantially modify a given feature based on how users react to
              it)
            </li>
            <li>
              Develop new features and services (for example, we may decide to
              build a new interests-based feature further to requests received
              from users)
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>
          E. To Prevent, Detect and Fight Fraud and Other Illegal or
          Unauthorized Activities
        </h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              Find and address ongoing, suspected or alleged violations of our
              Terms of Use, notably through the review of reports and
              interactions between members
            </li>
            <li>
              Better understand and design countermeasures against violations of
              our Terms of Use
            </li>
            <li>
              Retain data related to violations of our Terms of Use to address
              the violation and prevent against recurrences
            </li>
            <li>
              Enforce or exercise our rights, for example our rights set out in
              our Terms of Use
            </li>
            <li>
              Communicate to individuals who submit a report, including what
              we’ve done as a result of their submission
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>F. To Ensure Legal Compliance</h1>
      </div>
      <div className="content">
        <p>Comply with legal requirements</p>
      </div>
      <div className="content">
        <p>Assist law enforcement</p>
      </div>
      <div className="content1">
        <p>
          To process your information as described in this Privacy Policy, we
          rely on the following legal bases:
        </p>
        <p>
          <>Provide our service to you:</> The reason we process your
          information for purposes A and B above is to perform the contract that
          you have with us. For instance, as you go about using our service to
          build meaningful connections, we use your information to maintain your
          account and your profile, make it viewable to other users and
          recommend other users to you and to otherwise provide our free and
          paid features to you and other users.
        </p>
        <p>
          Legitimate interests: We process your information for purposes C, D
          and E above, based on our legitimate interest. For instance, we
          analyze users’ behavior on our services to continuously improve our
          offerings, we suggest offers we think might interest you and promote
          our own services, we process information to help keep our users safe
          and we process data where necessary to enforce our rights, assist law
          enforcement and enable us to defend ourselves in the event of a legal
          action.
        </p>
        <p>
          <>Comply with applicable laws and regulations:</> We process your
          information for purpose F above where it is necessary for us to comply
          with applicable laws and regulations and evidence our compliance with
          applicable laws and regulations. For example, we retain traffic data
          and data about transactions in line with our accounting, tax and other
          statutory data retention obligations and to be able to respond to
          valid access requests from law enforcement. We also keep data
          evidencing consents users give us and decisions they may have taken to
          opt-out of a given feature or processing.
        </p>
        <p>
          <>Consent:</> If you choose to provide us with information that may be
          considered “special” or “sensitive” in certain jurisdictions, such as
          your sexual orientation, you’re consenting to our processing of that
          information in accordance with this Privacy Policy. From time to time,
          we may ask for your consent to collect specific information such as
          your precise geolocation or use your information for certain specific
          reasons. In some cases, you may withdraw your consent by adapting your
          settings (for instance in relation to the collection of our precise
          geolocation) or by deleting your content (for instance where you
          entered information in your profile that may be considered “special”
          or “sensitive”). In any case, you may withdraw your consent at any
          time by contacting us at the address provided at the end of this
          Privacy Policy.
        </p>
      </div>
      <div className="sub-heading1">
        <h1>
          To process your information as described in this Privacy Policy, we
          rely on the following legal bases:
        </h1>
      </div>
      <div className="sub-heading">
        <h1>Provide our service to you</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>Maintain your account and profile</li>
            <li>Make your profile viewable to other users</li>
            <li>Recommend other users to you</li>
            <li>Provide our free and paid features to you and other users</li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>Legitimate interests</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              Analyze users’ behavior on our services to continuously improve
              our offerings
            </li>
            <li>
              Suggest offers we think might interest you and promote our own
              services
            </li>
            <li>Help keep our users safe</li>
            <li>
              Enforce our rights, assist law enforcement, and defend ourselves
              in legal actions
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>Comply with applicable laws and regulations</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>Comply with legal requirements</li>
            <li>Retain traffic and transaction data</li>
            <li>Respond to valid access requests from law enforcement</li>
            <li>Keep data evidencing consents and opt-out decisions</li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>Consent</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>Process information considered "special" or "sensitive"</li>
            <li>Collect specific information such as precise geolocation</li>
            <li>Withdraw consent by adapting settings or deleting content</li>
            <li>Withdraw consent at any time by contacting us</li>
          </ul>
        </p>
      </div>
      <div className="main-heading">
        <h1>4.How We Share Information</h1>
      </div>
      <div className="content">
        <p>
          Our goal is to help you make meaningful connections, the main sharing
          of users’ information is, of course, with other users. Read on for
          more details about how your information is shared with others.
        </p>
      </div>
      <div className="sub-heading">
        <h1>With other users</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              You share information with other users when you voluntarily
              disclose information on the service (including your public
              profile).
            </li>
            <li>
              If you choose to limit the audience for all or part of your
              profile or for certain content or information about you, then it
              will be visible according to your settings.
            </li>
            <li>
              If someone submits a report involving you, we may communicate to
              the reporter actions, if any, we took as a result of their report.
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>With our service providers and partners</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              We use vendors to help us operate, distribute, market, and improve
              our services, such as data hosting and maintenance, analytics,
              customer care, marketing, advertising, payment processing, and
              security operations.
            </li>
            <li>
              We share information with vendors who distribute and assist us in
              advertising our services, including limited information on you in
              hashed, non-human readable form.
            </li>
            <li>
              We follow a strict vetting process prior to engaging any vendor or
              working with any partner, and they must agree to strict
              confidentiality obligations.
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>For corporate transactions</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              We may transfer your information if we are involved, whether in
              whole or in part, in a merger, sale, acquisition, divestiture,
              restructuring, reorganization, dissolution, bankruptcy, or other
              change of ownership or control.
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>With law enforcement / when required by law</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              We may disclose your information if reasonably necessary to comply
              with a legal process, such as a court order, subpoena, or search
              warrant, government/law enforcement investigation, or other legal
              requirements.
            </li>
            <li>
              We may disclose your information to assist in the prevention or
              detection of crime (subject to applicable law).
            </li>
            <li>
              We may disclose your information to protect the safety of any
              person.
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>To enforce legal rights</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              We may share information if disclosure would mitigate our
              liability in an actual or threatened lawsuit.
            </li>
            <li>
              We may share information as necessary to protect our legal rights
              and the legal rights of our users, business partners, or other
              interested parties.
            </li>
            <li>
              We may share information to enforce our agreements with you.
            </li>
            <li>
              We may share information to investigate, prevent, or take other
              action regarding illegal activity, suspected fraud, or other
              wrongdoing.
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>With your consent or at your request</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              We may ask for your consent to share your information with third
              parties, and we will make it clear why we want to share the
              information.
            </li>
            <li>
              We may use and share non-personal information (meaning information
              that, by itself, does not identify who you are, such as device
              information, general demographics, general behavioral data,
              location in de-identified form), as well as personal information
              in hashed, non-human readable form, under any of the above
              circumstances.
            </li>
          </ul>
        </p>
      </div>
      <div className="main-heading">
        <h1>5.Cross-Border Data Transfers</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              Sharing of information as laid out in Section 5 involves
              cross-border data transfers to the United States of America and
              other jurisdictions that may have different laws about data
              processing.
            </li>
            <li>
              When we transfer personal information outside of the EEA, the
              United Kingdom, Switzerland, or other countries with data
              protection laws deemed adequate by the European Commission or
              other competent governmental body, we use standard contract
              clauses or other appropriate transfer mechanisms.
            </li>
            <li>
              We are currently reviewing transfers to our vendors and associated
              legal basis further to the recent Court of Justice for the
              European Union’s ruling on transfers of personal data to the USA.
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>6. Your Rights and Choices</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              <strong>Access / Update tools in the service.</strong> Tools and
              account settings can help you access, rectify, or remove
              information that you provided to us and that’s associated with
              your account directly within the service. If you have any
              questions on those tools and settings, please contact our customer
              care team for help here.
            </li>
            <li>
              <strong>Device permissions.</strong> Mobile platforms can have
              permission systems for specific types of device data and
              notifications, such as phone contacts, pictures, location
              services, push notifications, and advertising identifiers. You can
              change your settings on your device to either consent or oppose
              the collection or processing of the corresponding information or
              the display of the corresponding notifications. However, certain
              services may lose functionality if you do so.
            </li>
            <li>
              <strong>Uninstall.</strong> You can stop all information
              collection by an app by uninstalling it using the standard
              uninstall process for your device. Remember that uninstalling an
              app does NOT close your account. To close your account, please use
              the corresponding functionality on the service.
            </li>
            <li>
              <strong>Account closure.</strong> You can close your account by
              using the corresponding functionality directly on the service.
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>Privacy Rights</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              <strong>Access/know.</strong> You may have the right to request a
              copy of the information we keep about you, and in certain
              circumstances, to receive this in a portable format. You can
              exercise your right to access directly within the service by
              putting in a request.
            </li>
            <li>
              <strong>Delete/erase.</strong> You may request that we delete the
              personal information we keep about you. You can exercise your
              right to delete by submitting a request here.
            </li>
            <li>
              <strong>Correct/rectify/update.</strong> You can correct most
              information you provided to us by editing your profile directly in
              the service. If you believe the information we hold about you is
              inaccurate, you may contact us here to rectify it.
            </li>
            <li>
              <strong>Object/restrict.</strong> You may also have the right to
              object to or request that we restrict certain processing. To do
              so, please contact us.
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>Identity Verification and Limitations</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              For your protection and the protection of all our users, we may
              ask you to provide proof of identity before we can answer the
              above requests.
            </li>
            <li>
              We may reject requests if we are unable to authenticate you, if
              the request is unlawful or invalid, or if it may infringe on trade
              secrets, intellectual property, or the privacy or other rights of
              someone else.
            </li>
            <li>
              If you wish to receive information relating to another user, such
              as a copy of any messages you received from them through our
              service, the other user will have to contact us to submit a
              separate request for their information. We may also ask them to
              provide proof of identity before we can answer the request.
            </li>
            <li>
              We may not be able to accommodate certain requests to object to or
              restrict the processing of personal information, especially where
              such requests would prevent us from providing our service to you.
              For instance, we cannot provide our service if we do not have your
              date of birth and thus cannot ensure that you are 18 years of age
              or older.
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>7. How Long We Retain Your Information</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              We keep your personal information only as long as we need it for
              legitimate business purposes (as laid out in Section 4) and as
              permitted by applicable law.
            </li>
            <li>
              If you decide to stop using our services, you can close your
              account, and your profile will stop being visible to other users.
            </li>
            <li>
              We will close your account automatically if you are inactive for a
              period of two years.
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>Account Closure and Data Deletion</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              After your account is closed, we will delete your personal
              information, as detailed below:
            </li>
            <li>
              To protect the safety and security of our users, we implement a
              safety retention window of three months following account closure.
              During this period, we keep your information in case it might be
              necessary to investigate unlawful or harmful conduct. The
              retention of information during this safety retention window is
              based on our legitimate interest and that of potential third-party
              victims.
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>Post-Safety Retention Window Data Handling</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              Once the safety retention window elapses, we delete your data and
              only keep limited information for specified purposes, as outlined
              below:
            </li>
            <li>
              <strong>Legal Data Retention Obligations:</strong> We maintain
              limited data to comply with legal data retention obligations,
              including keeping transaction data for 10 years to comply with tax
              and accounting legal requirements. We may also need to keep
              “traffic data”/logs for one year to comply with legal data
              retention obligations. We keep records of consents users give us
              for five years to evidence our compliance with applicable law.
            </li>
            <li>
              <strong>Legitimate Interest:</strong> We maintain limited
              information on the basis of our legitimate interest, such as
              keeping customer care records, supporting data, and imprecise
              location of download/purchase to support our customer care
              decisions, enforce our rights, and enable us to defend ourselves
              in the event of a claim. We may also retain profile data in
              anticipation of potential litigation, for the establishment,
              exercise, or defense of legal claims, and data necessary to
              prevent users who were banned or found to be under the age of 18
              from opening a new account, for as long as necessary to ensure the
              safety and vital interests of our users.
            </li>
            <li>
              <strong>Outstanding or Potential Issues:</strong> We maintain
              information on the basis of our legitimate interest where there is
              an outstanding or potential issue, claim, or dispute requiring us
              to keep information. For instance, if we receive a valid legal
              subpoena or request asking us to preserve data, we would need to
              keep the data to comply with our legal obligations. Data may also
              be retained if it is necessary as part of legal proceedings.
            </li>
          </ul>
        </p>
      </div>
      <div className="sub-heading">
        <h1>8 .No Children Allowed</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              Our services are restricted to individuals who are 18 years of age
              or older.
            </li>
            <li>
              We do not permit individuals under the age of 18 on our platform.
            </li>
            <li>
              If you suspect that a user is under the age of 18, please use the
              reporting mechanism available on the service.
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>9. Job Candidates, Contractors, and Vendor Representatives</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              We process the personal information of our job candidates,
              contractors, and vendor representatives as part of our recruitment
              and talent management operations and the management of the
              services that contractors and vendors provide to us.
            </li>
            <li>
              If you are a job candidate, contractor, or vendor representative
              of Realate, certain relevant terms of this Privacy Policy apply to
              our processing of your personal information, including sections
              that discuss the entity responsible for processing your personal
              information, transfers of personal information, rights you may
              have under applicable law, and how to contact us.
            </li>
            <li>
              For job applicants, the personal information we process about you
              may vary depending on the job you seek but typically includes what
              you provide as part of your job application, as well as
              professional qualifications, background, and reference information
              shared by recruiters or other third parties. We use this
              information to support the recruitment process, which may lead to
              an employment contract.
            </li>
            <li>
              For contractors and vendor representatives, we may process
              identification information and work-related information necessary
              to manage our relationship with you and your employer, which is
              necessary for the performance of the services agreement and to
              establish, exercise, or defend potential legal claims.
            </li>
            <li>
              We may share personal information with service providers that
              assist us with recruitment and technical data processing
              operations. We keep your personal information only as long as
              necessary for those purposes.
            </li>
          </ul>
        </p>
      </div>

      <div className="sub-heading">
        <h1>10. Privacy Policy Changes</h1>
      </div>
      <div className="content">
        <p>
          <ul>
            <li>
              Because we’re always looking for new and innovative ways to help
              you build meaningful connections and strive to ensure our
              explanations about data practices remain up-to-date, this policy
              may change over time.
            </li>
            <li>
              We will notify you before any material changes take effect so that
              you have time to review the changes.
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
}
export default Policy;
